import React from 'react';
import "react-multi-carousel/lib/styles.css";
import HSPage from '../../auth/hspage';
import '../../pages/vijaya.style.scss';
import BlogItem from '../../components/vijaya/blog.component';
import AwardsAndRecognitions from '../../components/vijaya/awards.component';
import connect from '../diagnostics/diagnostic.connect';
import { withTranslation } from '../../../i18n';
import ErrorBoundary from '../layout/widgets/errorboundary/errorboundary.component';
import "video-react/dist/video-react.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Config } from '../../base/portal_env_config/config';
import dynamic from 'next/dynamic';
import VijayaService from './vijaya.service';
const YoutubeEmbed = dynamic(() => import('../layout/widgets/youtube/youtubembed.component'));

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};
class HappyCustomerList extends HSPage {
    state: any = {
        packageIndex: 0,
        visible: false,
        showYoutube: false,
        blogPosts:[]
    }
    props: any;
    packageDetails: any;

    constructor(props) {
        super(props);
        let awardBanners = require('public/vdc/data/vdcpackagebanners.json');
        this.state.awardBanners = awardBanners;
    }

    componentDidMount(): void {
        this.setState({ showYoutube: true });
        VijayaService.getRecentBlogs().then((res) => {
            if (res.data && res.data.length > 0) {
                const blogData = res.data;
                this.setState({blogPosts : blogData});
            }
        });
    }

    getHappyList() {
        let UIPackageList = [];
        let UIPackageItem =
            <div className="container-fluid happy_bgcolor mb-4">
                <div className="container">
                    <div className='row'>
                        <div key="hc1key1" className='col-lg-7 col-md-7 col-sm-12 happy_txt'>
                            <div className='row'>
                                <div key="hc2key1" className='col-lg-12 col-md-12 col-sm-12'>
                                    <h3>Our Happy Customers</h3>
                                </div>
                                <div key="hc2key2" className='col-lg-12 col-md-12 col-sm-12'>
                                    <Carousel partialVisible
                                        responsive={responsive}
                                        showDots={true}
                                        keyBoardControl={true}
                                        containerClass="carousel-container"
                                        itemClass="carousel-item-padding-40-px" ssr={true} deviceType="desktop">
                                        <div key='hc_1' className="oc-item">
                                            <div className="happy d-flex">
                                                <div className="testi-content">

                                                    <p className="quotes-text">
                                                        <div className='row'>

                                                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                                                <span className="customer-review-text">
                                                                    Overall, it was excellent, and I really appreciate the great hospitality offered by Miss Shree Latha. Keep it up!
                                                                </span>
                                                            </div>
                                                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                                                <p className='sai_txt'><b>Dr. P. Srinivas </b></p>
                                                            </div>
                                                        </div>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div key='hc_2' className="oc-item">
                                            <div className="happy d-flex">
                                                <div className="testi-content">
                                                    <p className="quotes-text">
                                                        <div className='row'>
                                                            <div key="hcsskey0" className='col-lg-12 col-md-12 col-sm-12'>
                                                                <h5>Our Happy Customers</h5>
                                                            </div>
                                                            <div key="hcsskey1" className='col-lg-12 col-md-12 col-sm-12'>
                                                                <span className="customer-review-text">
                                                                    They offered a prompt service and excellent hospitality. Mainly, I would like to mention Mr. Omar,
                                                                    who was there with me the entire time and guided me through all my check-ups.
                                                                </span>
                                                            </div>
                                                            <div key="hcsskey2" className='col-lg-12 col-md-12 col-sm-12'>
                                                                <p className='sai_txt'><b>S. Panigrahi </b></p>
                                                            </div>
                                                        </div>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div key='hc_3' className="oc-item">
                                            <div className="happy d-flex">
                                                <div className="testi-content">
                                                    <p className="quotes-text">
                                                        <div className='row'>
                                                            <div key="hckey3" className='col-lg-12 col-md-12 col-sm-12'>
                                                                <span className="customer-review-text">
                                                                    The service offered were simply excellent and the staff was immensely cooperative.
                                                                </span>
                                                            </div>
                                                            <div key="hckey4" className='col-lg-12 col-md-12 col-sm-12'>
                                                                <p className='sai_txt'><b>Shanta Reddy</b></p>
                                                            </div>
                                                        </div>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div key='hc_4' className="oc-item">
                                            <div className="happy d-flex">
                                                <div className="testi-content">
                                                    <p className="quotes-text">
                                                        <div className='row'>
                                                            <div key="hckey5" className='col-lg-12 col-md-12 col-sm-12'>
                                                                <span className="customer-review-text">
                                                                    The best part about Vijaya Diagnostic is that they make you feel right at home. Also, the service is incredible, and the entire staff offers a dedicated patient care, which is excellent.
                                                                </span>
                                                            </div>
                                                            <div key="hckey6" className='col-lg-12 col-md-12 col-sm-12'>
                                                                <p className='sai_txt'><b>Zaki Ahmad</b></p>
                                                            </div>
                                                        </div>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div key='hc_5' className="oc-item">
                                            <div className="happy d-flex">
                                                <div className="testi-content">
                                                    <p className="quotes-text">
                                                        <div className='row'>
                                                            <div key="hckey7" className='col-lg-12 col-md-12 col-sm-12'>
                                                                <span className="customer-review-text">
                                                                    The service offered at Vijaya Diagnostic is truly excellent. A well-trained staff and good doctors, they provide a complete package.
                                                                </span>
                                                            </div>
                                                            <div key="hckey8" className='col-lg-12 col-md-12 col-sm-12'>
                                                                <p className='sai_txt'><b> Rajesh </b></p>
                                                            </div>
                                                        </div>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </Carousel>
                                </div>
                            </div>
                        </div>
                        <div key="hc1key2" className='col-lg-5 col-md-5 col-sm-12'>
                            <img loading="lazy" src="https://images.vijayadiagnostic.com/website/slider_img2.png" className='happy_image' alt="Our happy customer" />
                        </div>
                    </div>
                </div>
            </div>




        UIPackageList.push(UIPackageItem)
        if (UIPackageList.length > 0) {
            return (
                UIPackageList
            )
        }
    }

    getBlogList() {
        let blogsList = [];
        if (this.state.blogPosts && this.state.blogPosts.length > 0) {
            this.state.blogPosts.map((blogPost: any, index) => {
                let blogItem =

                    <div key={"blog-div-" + index}>
                        <BlogItem blogPost={blogPost}></BlogItem>
                    </div>

                blogsList.push(blogItem)
            })
        }
        return (blogsList);
    }

    getAwardList() {
        let awardList = [];
        if (this.state.awardBanners && this.state.awardBanners.length > 0) {
            this.state.awardBanners.map((award: any, index) => {
                let awardItem =
                    <div key={"award-div-" + index}>
                        <AwardsAndRecognitions packageItem={award}>
                        </AwardsAndRecognitions>
                    </div>
                awardList.push(awardItem)
            })
        }
        return (awardList)

    }

    player: any;
    showModal = () => {

        this.setState({

            visible: true,

        });
    };
    hideModal = () => {
        this.setState({
            visible: false
        });
    };
    pause = () => {
        this.player.pause();
    };

    render() {
        return (
            <ErrorBoundary>
                {/* <div className="container-fluid happy_bgcolor mb-4" style={{
                    backgroundImage: `url("https://images.vijayadiagnostic.com/website/slider_img.png")`,
                    backgroundSize: 'cover',
                    overflow: 'hidden',
                    padding: '15px 15px'
                }}> */}
                {this.props.disableHappyCustomer != true &&
                    <div className="container-fluid happy_bgcolor mb-4">
                        <div className="container">
                            <div className='row'>
                                <div className='col-lg-7 col-md-7 col-sm-12 happy_txt'>
                                    <div className='row'>
                                        <div className='col-lg-12 col-md-12 col-sm-12'>
                                            <h4 className='our_txt_happy'>Our Happy Customers</h4>
                                        </div>
                                        <div className='col-lg-12 col-md-12 col-sm-12'>
                                            <Carousel partialVisible
                                                autoPlay={true}
                                                responsive={responsive}
                                                showDots={true}
                                                keyBoardControl={true}

                                                containerClass="carousel-container"
                                                itemClass="carousel-item-padding-40-px" ssr={true} deviceType="desktop">
                                                <div key="hc1" className="oc-item">
                                                    <div className="happy d-flex">
                                                        <div className="testi-content">

                                                            <p className="quotes-text">
                                                                <div className='row'>
                                                                    <div className='col-lg-12 col-md-12 col-sm-12'>
                                                                        <span className="customer-review-text">
                                                                            Overall, it was excellent, and I really appreciate the great hospitality offered by Miss Shree Latha. Keep it up!
                                                                        </span>
                                                                    </div>
                                                                    <div className='col-lg-12 col-md-12 col-sm-12'>
                                                                        <p className='sai_txt'><b>Dr. P. Srinivas </b></p>
                                                                    </div>
                                                                </div>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div key="hc2" className="oc-item">
                                                    <div className="happy d-flex">
                                                        <div className="testi-content">
                                                            <p className="quotes-text">
                                                                <div className='row'>
                                                                    <div className='col-lg-12 col-md-12 col-sm-12'>
                                                                        <span className="customer-review-text">
                                                                            They offered a prompt service and excellent hospitality. Mainly, I would like to mention Mr. Omar,
                                                                            who was there with me the entire time and guided me through all my check-ups.
                                                                        </span>
                                                                    </div>
                                                                    <div className='col-lg-12 col-md-12 col-sm-12'>
                                                                        <p className='sai_txt'><b>S. Panigrahi </b></p>
                                                                    </div>
                                                                </div>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div key="hc3" className="oc-item">
                                                    <div className="happy d-flex">
                                                        <div className="testi-content">
                                                            <p className="quotes-text">
                                                                <div className='row'>
                                                                    <div className='col-lg-12 col-md-12 col-sm-12'>
                                                                        <span className="customer-review-text">
                                                                            The service offered were simply excellent and the staff was immensely cooperative.
                                                                        </span>
                                                                    </div>
                                                                    <div className='col-lg-12 col-md-12 col-sm-12'>
                                                                        <p className='sai_txt'><b>Shanta Reddy</b></p>
                                                                    </div>
                                                                </div>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div key="hc4" className="oc-item">
                                                    <div className="happy d-flex">
                                                        <div className="testi-content">
                                                            <p className="quotes-text">
                                                                <div className='row'>
                                                                    <div className='col-lg-12 col-md-12 col-sm-12'>
                                                                        <span className="customer-review-text">
                                                                            The best part about Vijaya Diagnostic is that they make you feel right at home. Also, the service is incredible, and the entire staff offers a dedicated patient care, which is excellent.
                                                                        </span>
                                                                    </div>
                                                                    <div className='col-lg-12 col-md-12 col-sm-12'>
                                                                        <p className='sai_txt'><b>Zaki Ahmad</b></p>
                                                                    </div>
                                                                </div>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div key="hc5" className="oc-item">
                                                    <div className="happy d-flex">
                                                        <div className="testi-content">
                                                            <p className="quotes-text">
                                                                <div className='row'>
                                                                    <div className='col-lg-12 col-md-12 col-sm-12'>
                                                                        <span className="customer-review-text">
                                                                            The service offered at Vijaya Diagnostic is truly excellent. A well-trained staff and good doctors, they provide a complete package.
                                                                        </span>
                                                                    </div>
                                                                    <div className='col-lg-12 col-md-12 col-sm-12'>
                                                                        <p className='sai_txt'><b> Rajesh </b></p>
                                                                    </div>
                                                                </div>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Carousel>
                                        </div>
                                    </div>

                                </div>
                                <div className='col-lg-5 col-md-5 col-sm-12 image_padding'>
                                    <img src="https://images.vijayadiagnostic.com/website/slider_img2.png" className='happy_image' alt="Our happy customer" />
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/*///////////////////////// Our Happy Customers////////////// */}
                <div className="container">
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-12 mt-3 blogs_happytxt'>
                            <div className="d-flex justify-content-center mt-2">
                                <h6 className="center mt-2">
                                    <p>Blogs</p></h6></div>

                            <div className="mt-3">
                                <div className="row row_margin" id="carddata">
                                    <div className="col-md-12 mb-4 filter-js happy_blog blog_arrow">
                                        {/* <i className="fa fa-arrow-circle-o-right pointer-cursor" aria-hidden="true" onClick={() => Auth.navigateTo("/blog/")}></i> */}
                                        <Carousel partialVisible
                                            responsive={responsive}
                                            autoPlay={true}
                                            keyBoardControl={true}
                                            infinite={true}
                                            containerClass="carousel-container"
                                            itemClass="carousel-item-padding-40-px" ssr={true} deviceType="desktop">
                                            {this.getBlogList()}
                                        </Carousel>
                                    </div>
                                </div>

                            </div>



                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-12 mt-3'>
                            <div className="d-flex justify-content-center mt-2 blogs_happytxt">
                                <h6 className="center mt-2 award_happytxt">
                                    <p>Awards & Recognitions</p></h6></div>
                            <div className="mt-3">
                                <div className="row row_margin" id="carddata">
                                    <div className="col-md-12 mb-4 filter-js happy_blog">
                                        {/* <i className="fa fa-arrow-circle-o-right pointer-cursor" aria-hidden="true" onClick={() => Auth.navigateTo("/awards-and-accolades/")}></i> */}
                                        <Carousel partialVisible
                                            responsive={responsive}
                                            autoPlay={true}
                                            infinite={true}
                                            keyBoardControl={true}
                                            containerClass="carousel-container"
                                            itemClass="carousel-item-padding-40-px" ssr={true} deviceType="desktop">
                                            {this.getAwardList()}
                                        </Carousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-12 mt-3'>
                            <div className="d-flex justify-content-center mt-2 blogs_happytxt">
                                <h6 className="center mt-2 diagnostic_happytxt">
                                    <p>Diagnostic Education</p></h6></div>
                            <div className="mt-3">
                                <div className="row row_margin" id="carddata">
                                    {this.state.showYoutube && <div className="col-md-12 mb-4 filter-js video_border">
                                        <a className='' target="_blank" href={Config.portal.youtube} aria-label="youtube-play">
                                            <i className="fa fa-arrow-circle-o-right pointer-cursor" aria-hidden="true" ></i>
                                        </a>
                                        <YoutubeEmbed url="https://www.youtube.com/embed/videoseries?list=PLaFAp7uSQE1eLulYclRDrCsiYPIx3dgFy"
                                            title="Webinar on Clinico - Radiological Evaluation of Pulmonary Thromboembolism | Vijaya Diagnostic Centre"
                                            height="278" extFrameId="yt1234" divClassName="video-responsive" />
                                    </div>}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </ErrorBoundary>
        )
    }
}
export default connect(withTranslation(['common'])(HappyCustomerList));