import React, { Component } from 'react';
import connect from '../diagnostics/diagnostic.connect';
import { withTranslation } from '../../../i18n';
import Link from 'next/link';
import ErrorBoundary from '../layout/widgets/errorboundary/errorboundary.component';
class BlogItem extends Component {

    props: any;
    constructor(props) {
        super(props);
    }

    render() {
        let blogPost = this.props.blogPost;
        return (
            <ErrorBoundary>
                <div key={'blog-'+blogPost.id} className="card " id="window">
                    <img loading="lazy" className="card-img-top" src={blogPost.jetpack_featured_media_url} alt={blogPost.jetpack_featured_media_url} />
                    <div className="card-body">
                        <Link href={blogPost.link.includes('mri-scan') ? '/services/mri-scan' : blogPost.link}>
                            <a aria-label={blogPost.title?.rendered} >
                                <h6 className="card-title sub-title" >
                                    {blogPost.title?.rendered}
                                </h6>
                            </a>
                        </Link>
                        <p className="card-text para1 text-truncate">
                            {blogPost.excerpt?.rendered.replace(/(<([^>]+)>)/gi, "")}
                        </p>
                        <div className="row">
                            <div className="indi-awards col-lg-4">
                                <Link href={blogPost.link.includes('mri-scan') ? '/services/mri-scan' : blogPost.link}>
                                    <a aria-label={blogPost.title?.rendered}>Read More</a>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </ErrorBoundary>
        )
    }
}
export default connect(withTranslation(['common'])(BlogItem));