import React, { Component } from 'react';
import connect from '../diagnostics/diagnostic.connect';
import { withTranslation } from '../../../i18n';
import Link from '../layout/widgets/activelink/activelink';
import ErrorBoundary from '../layout/widgets/errorboundary/errorboundary.component';
import FileUtil from '../../base/utils/fileutil';
class AwardsAndRecognitions extends Component {

    props: any;
    constructor(props) {
        super(props);
    }

    render() {
        let packageDetails = this.props?.packageItem;
        let webpSupport = FileUtil.checkWebPSupport();
        return (
            <ErrorBoundary>
                <div key={packageDetails?.name} className="card" id="award-div">
                    <img loading="lazy" className="card-img-top" src={webpSupport ? packageDetails?.packageImage2 : packageDetails?.packageImage2.replace('.webp', '.png')} alt={packageDetails?.alt} />
                    <div className="card-body">
                        <Link href={`/awards-and-accolades/${packageDetails?.urlname}`}>
                            <a aria-label={packageDetails?.urlname} >
                                <h6 className="card-title sub-title">
                                    {packageDetails?.name}
                                </h6>
                            </a>
                        </Link>
                        <p className="card-text para1 text-truncate">
                            {packageDetails?.des}
                        </p>

                        {!this.props.fromCampaign &&
                            <Link href={`/awards-and-accolades/${packageDetails?.urlname}`}>
                                <a aria-label={packageDetails?.urlname} className="indi-awards">{packageDetails?.readmore}</a></Link>
                        }
                    </div>

                </div>
            </ErrorBoundary>
        )
    }
}
export default connect(withTranslation(['common'])(AwardsAndRecognitions));