import AxiosService from '../../base/portal_env_config/axiosService';
import { paths } from '../../base/portal_env_config/urlconstants';

export default class VijayaService {
    public static uploadedFiles = [];

    public static async currentAndFutureJobOpenings(userDetails) {
        return AxiosService.httpGet(paths.UPDATE_CURRENT_AND_FUTURE_JOB_OPENINGS, userDetails).then((data) => {
            return Promise.resolve(data);
        }).catch((err) => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }

    public static async getRecentBlogs() {
        return AxiosService.httpExternalGet('https://www.vijayadiagnostic.com/blog/wp-json/wp/v2/posts/?per_page=4').then((data) => {
            return Promise.resolve(data);
        }).catch((err) => {
            if (err) {
                console.log(err);
                return Promise.reject(err);
            }
        });
    }
}